import { FC, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useMutation, useQuery } from "react-query";
import { CircularProgress } from "@mui/material";
import { generateOTP, getMFATypeByUser, maskPhoneNumber, pushTagEvent } from "../../Common/Helper";
import HttpService from "../../Services/HttpService";
import ToastService from "../../VideoLib/Common/ToastService";
import { AuthContext } from "../../Contexts/AuthContext";
import NavBar from "../Nav/NavBar";

const ValidateMFA: FC = () => {
    const context = useContext(AuthContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [code, setCode] = useState<string>("");
    const navigate = useNavigate();
    const [mfaType, setMfaType] = useState<string>("");
    const generateCodeMutation = useMutation({
        mutationFn: () =>
            generateOTP({
                mfa_type: "PHONE_OTP"
            })
    });
    const isOTPGenerated = useRef<boolean>(false);

    useQuery({
        retry: false,
        queryFn: () => getMFATypeByUser(context.user?.userId!),
        onError: (error: any) => {
            ToastService.error("Error fetching mfa type for user");
        },
        onSuccess: (data: any) => {
            setMfaType(data.mfa_type);
        }
    });

    useEffect(() => {
        if (mfaType === "PHONE_OTP" && !isOTPGenerated.current) {
            generateCodeMutation.mutate();
            isOTPGenerated.current = true;
        }
    });

    const validate = async () => {
        setLoading(true);
        try {
            await HttpService.postFormData("/v1/mfa/validate", {
                code,
                mfa_type: mfaType
            });
            const userData = JSON.parse(localStorage.getItem("userData") || "");
            userData.mfaVerified = true;
            pushTagEvent("e_mfa_verified");
            pushTagEvent("e_logged_in");
            localStorage.setItem("userData", JSON.stringify(userData));
            context.setUserData(userData);
            setLoading(false);
            navigate("/", { replace: true });
        } catch (error) {
            ToastService.error("Invalid code");
            setLoading(false);
        }
    };

    const resendCode = async () => {
        generateCodeMutation.mutate();
    };

    if (!mfaType) {
        return <CircularProgress />;
    }

    return (
        <>
            <NavBar theme="dark" />
            <div className="container container-max-width p-4">
                <h2>Two-Factor Authentication (2FA)</h2>
                <h6 className="pt-3 pb-3">
                    {mfaType === "AUTHENTICATOR"
                        ? "Open the two-step verification app on your mobile device to get your verification code"
                        : `Please enter the code you have received on your phone: ${maskPhoneNumber(context.user?.phoneNumber)}`}
                </h6>
                <input type="text" className="form-control mb-4" placeholder="Authentication code" onChange={e => setCode(e.target.value)} />
                <div className="d-flex">
                    <button className="btn btn-primary mb-3 px-3 text-white animated-text mr-3" disabled={!code || loading} onClick={validate}>
                        Authenticate
                    </button>
                    {mfaType === "PHONE_OTP" && (
                        <button className="btn btn-primary mb-3 px-3 text-white animated-text mr-3" onClick={resendCode}>
                            Resend Code
                        </button>
                    )}
                    {loading && <CircularProgress />}
                </div>
            </div>
        </>
    );
};

export default ValidateMFA;
