import "./App.scss";
import { Outlet, useLocation } from "react-router";
import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { H } from "highlight.run";
import Footer from "./Common/Footer";
import { APP_VERSION } from "./Common/Constants";
import { useContext, useEffect } from "react";
import { AuthContext } from "./Contexts/AuthContext";
import { pushTagEvent } from "./Common/Helper";

const darkTheme = createTheme({
    palette: {
        mode: "dark"
    },
    typography: {
        fontFamily: ["Chivo"].join(",")
    }
});

const queryClient = new QueryClient();

function App() {
    const location = useLocation();
    const authContext = useContext(AuthContext);

    useEffect(() => {
        H.getSessionDetails().then(({ url, urlWithTimestamp }) => localStorage.setItem("last_highlight_session", url));
    }, []);

    useEffect(() => {
        if (authContext.user) {
            pushTagEvent("e_user_data", {
                user_email: authContext.user.email,
                user_id: authContext.user.userId,
                logged_in: "yes"
            });
        }
    }, [authContext.user, location]);

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <QueryClientProvider client={queryClient}>
                <Outlet />
                <ToastContainer position="top-center" />
                <Footer version={APP_VERSION} />
            </QueryClientProvider>
        </ThemeProvider>
    );
}

export default App;
