import { toast } from "react-toastify";

class ToastService {
    static success(text, duration) {
        toast.success(text, {
            position: "top-center",
            autoClose: duration || 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
    }

    static error(text, duration) {
        toast.error(text, {
            position: "top-center",
            autoClose: duration || 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
    }

    static info(text, duration) {
        toast.info(text, {
            position: "top-center",
            autoClose: duration || 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
    }

    static warn(text, duration) {
        toast.warn(text, {
            position: "top-center",
            autoClose: duration || 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
    }
}

export default ToastService;
