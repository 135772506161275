import { FC, useContext, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useAuth } from "../../Contexts/AuthProvider";
import NavBar from "../Nav/NavBar";
import Popup from "../../Common/Popup";
import AuthenticatorMFA from "./AuthenticatorMFA";
import HttpService from "../../Services/HttpService";
import ToastService from "../../VideoLib/Common/ToastService";
import { AuthContext } from "../../Contexts/AuthContext";
import MFAOptions from "./MFAOptions";
import PhoneMFA from "./PhoneMFA";

const SetupMFA: FC = () => {
    const context = useContext(AuthContext);
    const { user } = useAuth();
    const [isAuthenticatorMFAOpen, setAuthenticatorMFAOpen] = useState<boolean>(false);
    const [isMFAOptionsOpen, setMFAOptionsOpen] = useState<boolean>(false);
    const [isPhoneMFAOpen, setPhoneMFAOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [generatedSecret, setGeneratedSecret] = useState<any>(null);

    const generateSecret = async () => {
        setLoading(true);
        try {
            const response = await HttpService.postFormData("/v1/mfa/generate/secret", null);
            if (response) {
                setGeneratedSecret(response);
            }
        } catch (error) {
            ToastService.error("Unable to setup 2FA");
        }
    };

    const handle2FAViaAuthenticator = async () => {
        setLoading(true);
        await generateSecret();
        setAuthenticatorMFAOpen(true);
        setLoading(false);
    };

    const authenticatoMFAComplete = (option: string, isVerified: boolean = false) => {
        option === "AUTHENTICATOR" ? setAuthenticatorMFAOpen(false) : setPhoneMFAOpen(false);
        if (isVerified) {
            const userData = JSON.parse(localStorage.getItem("userData") || "");
            userData.mfaEnabled = true;
            localStorage.setItem("userData", JSON.stringify(userData));
            context.setUserData(userData);
            context.signOut();
            ToastService.success("MFA successfully enabled. Logging out.");
        }
    };

    const handle2FAViaPhone = async () => {
        setLoading(true);
        await generateSecret();
        setLoading(false);
        setPhoneMFAOpen(true);
    };

    const chooseMFAOption = (option: string) => {
        setMFAOptionsOpen(false);
        if (option === "Authenticator") {
            handle2FAViaAuthenticator();
        } else {
            handle2FAViaPhone();
        }
    };

    const setupMFA = async () => {
        if (user?.mfaEnabled) {
            try {
                await HttpService.postFormData("/v1/mfa/disable", null);
                const userData = JSON.parse(localStorage.getItem("userData") || "");
                userData.mfaEnabled = false;
                localStorage.setItem("userData", JSON.stringify(userData));
                context.setUserData(userData);
                ToastService.success("MFA disabled successfully");
            } catch (error) {
                ToastService.error("Unable to disable 2FA");
            }
        } else {
            setMFAOptionsOpen(true);
        }
    };

    return (
        <>
            <NavBar theme="dark" />
            <div className="container container-max-width p-4">
                <h2>Multi factor autentication (2FA)</h2>
                <h6 className="pt-3 pb-3">Setup your account with TOTP two-factor authentication</h6>
                <div className="d-flex">
                    <button className="btn btn-primary mb-3 px-3 text-white animated-text mr-3" disabled={loading} onClick={setupMFA}>
                        {user?.mfaEnabled ? "Disable 2FA" : "Setup 2FA"}
                    </button>
                    {loading && <CircularProgress />}
                </div>
            </div>
            <Popup className="mfa-popup" isOpen={isAuthenticatorMFAOpen} onClose={() => setAuthenticatorMFAOpen(false)}>
                <AuthenticatorMFA handleClose={authenticatoMFAComplete} generatedSecret={generatedSecret} />
            </Popup>
            <Popup className="mfa-popup" isOpen={isPhoneMFAOpen} onClose={() => setPhoneMFAOpen(false)}>
                <PhoneMFA handleClose={authenticatoMFAComplete} generatedSecret={generatedSecret} />
            </Popup>
            <Popup className="mfa-popup" isOpen={isMFAOptionsOpen} onClose={() => setMFAOptionsOpen(false)}>
                <MFAOptions handleClose={chooseMFAOption} />
            </Popup>
        </>
    );
};

export default SetupMFA;
