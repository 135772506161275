import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { CircularProgress, Link } from "@mui/material";
import NavBar from "../Nav/NavBar";
import HttpService from "../../Services/HttpService";
import ToastService from "../../VideoLib/Common/ToastService";
import Error from "../../Common/Error";
import { ALLOWED_PROMO_CODES, EMAIL_REGEX } from "../../Common/Constants";
import { getErrorMessageFromJSON } from "../../Common/Helper";
import { Paths } from "../../Common/AppRoutes";

interface IResetPasswordData {
    email: string;
}

const ResetPassword: FC = () => {
    const navigate = useNavigate();
    const userEmail = sessionStorage.getItem("loginFlowEmail") || "";
    const [loading, setLoading] = useState<boolean>(false);
    const [resetPasswordEmailSent, setResetPasswordEmailSent] = useState<boolean>(false);
    const { register, formState, handleSubmit } = useForm<IResetPasswordData>({
        defaultValues: { email: userEmail },
        mode: "onChange"
    });

    const resetPassword = async (data: any) => {
        setLoading(true);
        try {
            await HttpService.postFormData("/v1/reset-password/trigger", { email: data.email });
            setResetPasswordEmailSent(true);
            setLoading(false);
        } catch (error) {
            const errorMessage = getErrorMessageFromJSON(error as Error);
            ToastService.error(errorMessage ? errorMessage : "Error while resetting password");
            setLoading(false);
        }
    };

    const handleLoginClick = () => {
        const promoCode = (localStorage.getItem("promo_code") || "").toLowerCase();
        let isPromoUser = ALLOWED_PROMO_CODES.includes(promoCode);
        navigate(Paths.landing + "?show_login=true" + (isPromoUser ? `&promo=${promoCode}` : ""));
    };

    return (
        <>
            <NavBar theme="dark" />
            <div className="container container-max-width p-4">
                <h2>Reset Password</h2>
                {resetPasswordEmailSent && (
                    <>
                        <h6 className="pt-3 pb-3">We've sent you an email to reset your password!</h6>
                        <button className="btn btn-primary m-3 ms-0 text-white animated-text" onClick={handleLoginClick}>
                            Sign In
                        </button>
                    </>
                )}
                {!resetPasswordEmailSent && (
                    <>
                        <h6 className="pt-3 pb-3">Please enter your email to proceed</h6>
                        <form onSubmit={handleSubmit(resetPassword)}>
                            <div className="form-group mb-4">
                                <input
                                    type="email"
                                    className={formState.errors.email && formState.touchedFields.email ? "form-control error" : "form-control"}
                                    {...register("email", { required: true, pattern: EMAIL_REGEX })}
                                    placeholder="Enter email"
                                />
                                {formState.errors.email && formState.errors.email?.type === "required" && <Error>Please enter email</Error>}
                                {formState.errors.email && formState.errors.email?.type === "pattern" && <Error>Please enter a valid email</Error>}
                            </div>
                            <div className="d-flex">
                                <button type="submit" className="btn btn-primary mb-3 px-3 text-white animated-text mr-3" disabled={loading}>
                                    Submit
                                </button>
                                {loading && <CircularProgress />}
                            </div>
                        </form>
                    </>
                )}
            </div>
        </>
    );
};

export default ResetPassword;
