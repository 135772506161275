import { Dialog, DialogContent } from "@mui/material";
import { FC } from "react";

interface PopupProps {
    isOpen: boolean;
    children: React.ReactNode;
    onClose: any;
    className?: string;
}

const Popup: FC<PopupProps> = (props: PopupProps) => {
    return (
        <div className="popup">
            <Dialog
                className={props.className}
                open={props.isOpen}
                maxWidth={false}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                onClose={props.onClose}
            >
                <DialogContent>{props.children}</DialogContent>
            </Dialog>
        </div>
    );
};

export default Popup;
