import { FC, useContext, useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { Divider, ListItemIcon, MenuItem, MenuList } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import LoginIcon from "@mui/icons-material/Login";
import { AuthContext } from "../../Contexts/AuthContext";
import MenuToggle from "./MenuToggle";
import Popup from "../../Common/Popup";
import { Paths } from "../../Common/AppRoutes";
import CreateEmovidButton from "../../Common/CreateEmovidButton";
import { isWhitelistedUser } from "../../Common/GuardedRoute";
import NotificationsIconButton from "./NotificationsIconButton";
import Login from "../Login/Login";
import Logo from "./Logo";
import CreatedVideosIcon from "../../Icons/CreatedVideosIcon";
import MoviIcon from "../../Icons/MoviIcon";
import ContactSalesIcon from "../../Icons/ContactSalesIcon";

const NavLinksContainer = styled.div`
    height: 100%;
`;

const LinksWrapper = styled.ul`
    margin: 0;
    padding: 0;
    height: 100%;
    list-style: none;
    background-color: #333;
    position: fixed;
    top: 0;
    left: 0px;
    width: 100%;
    z-index: 1100;
`;

const LinkItem = styled.li`
    padding: 0.5em 0.3em;
    font-size: 1.5rem;
    color: #222;
    display: flex;
    justify-content: center;
    transition: all 220ms ease-in-out;
`;

interface MobileNavLinksProps {
    theme?: string;
    hideCreateEmovidButtonInMenu?: boolean;
    disableSignup?: boolean;
}

const MobileNavLinks: FC<MobileNavLinksProps> = (props: MobileNavLinksProps) => {
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();
    const [isLoginOpen, setLoginOpen] = useState<boolean>(false);
    const [isOpen, setOpen] = useState<boolean>(false);

    const signInClicked = () => {
        setOpen(false);
        setLoginOpen(true);
    };

    const dashboardClicked = () => {
        setOpen(false);
        navigate(Paths.dashboard);
    };

    const myAccountClicked = () => {
        setOpen(false);
        navigate(Paths.myAccount);
    };

    const createdVideosClicked = () => {
        setOpen(false);
        navigate(Paths.createdVideos);
    };

    const getSupportClicked = () => {
        setOpen(false);
        navigate(Paths.getSupport);
    };

    const salesClicked = () => {
        setOpen(false);
        navigate(Paths.contactSales);
    };

    const sendFeedbackClicked = () => {
        setOpen(false);
        navigate(Paths.sendFeedback);
    };

    const loginComplete = (reload = true) => {
        setLoginOpen(false);
        localStorage.setItem("waitlist_signup", "true");
        if (reload) window.location.reload();
    };

    const useCasesClicked = () => {
        setOpen(false);
        navigate(Paths.useCases);
    };

    const usageGuideClicked = () => {
        setOpen(false);
        navigate(Paths.usageGuide);
    };

    const logoutClicked = () => {
        setOpen(false);
        authContext.signOut();
        navigate(Paths.landing);
    };

    const showExtraLinks = (): boolean => {
        return !authContext.user;
    };

    return (
        <NavLinksContainer>
            {isOpen && (
                <LinksWrapper>
                    <LinkItem className="d-flex flex-column pt-3 pb-3 ps-3">
                        <Logo theme={props.theme} />
                    </LinkItem>
                    <Divider />
                    <MenuList>
                        {isWhitelistedUser(authContext) && !props.hideCreateEmovidButtonInMenu && (
                            <CreateEmovidButton onSuccess={() => setOpen(false)} isMenuButton={true} buttonText="Create New Video" location="nav_menu"></CreateEmovidButton>
                        )}
                        {isWhitelistedUser(authContext) && (
                            <MenuItem onClick={dashboardClicked}>
                                <ListItemIcon>
                                    <DashboardIcon fontSize="small" />
                                </ListItemIcon>
                                My Inbox
                            </MenuItem>
                        )}
                        {isWhitelistedUser(authContext) && (
                            <MenuItem onClick={createdVideosClicked}>
                                <ListItemIcon>
                                    <CreatedVideosIcon style={{ width: "1.2rem", height: "1.2rem" }} />
                                </ListItemIcon>
                                Created Videos
                            </MenuItem>
                        )}
                        {showExtraLinks() && (
                            <MenuItem onClick={useCasesClicked}>
                                <ListItemIcon>
                                    <ListAltRoundedIcon width="24px" />
                                </ListItemIcon>
                                Use Cases
                            </MenuItem>
                        )}
                        <MenuItem onClick={usageGuideClicked}>
                            <ListItemIcon>
                                <MoviIcon width="24px" />
                            </ListItemIcon>
                            Usage Guide
                        </MenuItem>
                        {isWhitelistedUser(authContext) && (
                            <MenuItem onClick={myAccountClicked}>
                                <ListItemIcon>
                                    <ManageAccountsRoundedIcon fontSize="small" />
                                </ListItemIcon>
                                My Account
                            </MenuItem>
                        )}
                        {isWhitelistedUser(authContext) && <Divider className="ms-3 me-3" />}
                        <MenuItem onClick={getSupportClicked}>
                            <ListItemIcon>
                                <HelpRoundedIcon fontSize="small" />
                            </ListItemIcon>
                            Get Support
                        </MenuItem>
                        {isWhitelistedUser(authContext) && (
                            <MenuItem onClick={sendFeedbackClicked}>
                                <ListItemIcon>
                                    <ThumbUpAltRoundedIcon fontSize="small" />
                                </ListItemIcon>
                                Send Feedback
                            </MenuItem>
                        )}
                        <MenuItem onClick={salesClicked}>
                            <ListItemIcon>
                                <ContactSalesIcon />
                            </ListItemIcon>
                            Contact Sales
                        </MenuItem>
                        {!authContext.user && (
                            <MenuItem onClick={signInClicked}>
                                <ListItemIcon>
                                    <LoginIcon fontSize="small" />
                                </ListItemIcon>
                                Sign In
                            </MenuItem>
                        )}
                        {authContext.user && (
                            <MenuItem onClick={logoutClicked}>
                                <ListItemIcon>
                                    <LogoutIcon fontSize="small" />
                                </ListItemIcon>
                                Logout
                            </MenuItem>
                        )}
                    </MenuList>
                </LinksWrapper>
            )}
            <div className="position-fixed" style={{ top: "16px", right: "10px", zIndex: 1300 }}>
                {!isOpen && isWhitelistedUser(authContext) && <NotificationsIconButton />}
                <MenuToggle isOpen={isOpen} toggle={() => setOpen(!isOpen)} />
            </div>
            <Popup isOpen={isLoginOpen} onClose={() => setLoginOpen(false)} className="login-popup">
                <Login handleClose={loginComplete} disableSignup={props.disableSignup !== undefined ? props.disableSignup : true} />
            </Popup>
        </NavLinksContainer>
    );
};

export default MobileNavLinks;
