import { useContext, Component } from "react";
import { useNavigate } from "react-router";
import { IJWTTokenPayload } from "@emovid/payloads-library";
import jwt from "jwt-decode";
import { IUserInfo } from "../Interfaces/IUserInfo";
import { AuthContext } from "./AuthContext";
import { AccountType } from "../Enums/AccountType";
import { API_KEY, BACKEND_URL } from "../Common/Constants";
import { LogValues, getEncryptionKeys, identifyUserSession, pushTagEvent } from "../Common/Helper";
import { SetLoginUserInfoMap } from "../Util/UserInfoMap";
import HttpService from "../Services/HttpService";
import Progress from "../Common/Progress";
import { DefaultLoggedinUserPath, Paths } from "../Common/AppRoutes";
import axios from "axios";
import { isWhitelistedEmail, isWhitelistedUser } from "../Common/GuardedRoute";
import { CookiesDisabledPopup } from "../Common/CookiesDisabledPopup";

export interface AuthData {
    keys: any;
    signIn: Function;
    signOut: Function;
    setUserData: Function;
    setWhitelisted: Function;
    retrieveUserDataFromTokenAndRedirect: Function;
    user: IUserInfo | undefined;
    isWhitelisted: boolean;
}

interface AuthProps {
    navigate: any;
    children: React.ReactNode;
}

interface AuthState {
    user: IUserInfo | undefined;
    isWhitelisted: boolean;
    keys: any;
    loading: boolean;
    securityError: boolean;
}

export class AuthProvider extends Component<AuthProps, AuthState> {
    state: AuthState = {
        user: undefined,
        keys: undefined,
        isWhitelisted: false,
        loading: true,
        securityError: false
    };

    async componentDidMount() {
        try {
            if (!this.state.loading) return;
            await this.getCookies();
            await this.setToken();
            if (localStorage.getItem("userData")) {
                await this.setUserData();
            } else {
                this.setUserData();
            }
            this.setState({ loading: false });
        } catch (error: any) {
            if (error?.name === "SecurityError") {
                this.setState({ securityError: true, loading: false });
            }
        }
    }

    signIn = (accountType: AccountType, loginHint?: string) => {
        let currentPath = window.location.pathname + window.location.search;
        sessionStorage.setItem("redirectPath", currentPath === "/" ? DefaultLoggedinUserPath : currentPath);
        window.location.href = `${BACKEND_URL}/v1/auth/${accountType}?successRef=${window.location.pathname}&errorRef=/&loginHint=${loginHint || ""}`;
    };

    signOut = () => {
        this.setState({ user: undefined });
        localStorage.removeItem("userData");
        pushTagEvent("e_logout");
        LogValues("AuthProvider - sign out block");
    };

    setUserData = async () => {
        if (localStorage.getItem("userData")) {
            const token = JSON.parse(localStorage.getItem("userData") || "").token;
            let decodedToken: any;
            if (token) decodedToken = jwt(token as string);
            const time = new Date().getTime();
            if (!decodedToken) {
                this.setState({ user: undefined });
                localStorage.removeItem("userData");
                LogValues("AuthProvider - decoded token null or undefined", { pathname: window.location.pathname });
            } else if (decodedToken && decodedToken.exp * 1000 < time) {
                this.setState({ user: undefined });
                localStorage.removeItem("userData");
                LogValues("AuthProvider - decoded token expired", { pathname: window.location.pathname });
            } else {
                LogValues("AuthProvider - user data else block");
                let userDataParsed = JSON.parse(localStorage.getItem("userData") || "{}");
                this.setState({ user: userDataParsed });
                await this.checkEmailWhitelisted(userDataParsed.email);
                identifyUserSession({ userId: userDataParsed.userId, email: userDataParsed.email, name: userDataParsed.name, isInternal: userDataParsed.isInternal });
            }
        }
    };

    setToken = async () => {
        if (window.location.search.includes("token")) {
            const queryParams = new URLSearchParams(window.location.search);
            const token = queryParams.get("token") as string;
            await this.retrieveUserDataFromTokenAndRedirect(token);
        }
    };

    checkEmailWhitelisted = async (email: string) => {
        try {
            const response = await HttpService.getWithAPIKey(`/v1/whitelisted/status/${email}`);
            this.setState({ isWhitelisted: response.is_whitelisted });
            localStorage.setItem("last_whitelisted_status", `${response.is_whitelisted || isWhitelistedEmail({ user: this.state.user, isWhitelisted: false }, email)}`);
            return response.is_whitelisted;
        } catch (error) {}
    };

    setWhitelisted = () => {
        this.setState({ isWhitelisted: true });
        this.setUserData();
    };

    getKeys = async () => {
        const path = "/v1/keys";
        try {
            const keys = await getEncryptionKeys();
            this.setState({ keys });
        } catch (error: any) {
            return HttpService.handleResponse(path, error.response, true);
        }
    };

    getCookies = async () => {
        try {
            await axios.get(`${BACKEND_URL}/v1/generate/cookies/hls`, {
                headers: {
                    authorization: `Basic ${API_KEY}`
                },
                withCredentials: true
            });
        } catch (error: any) {
            console.error("Error while getting cookies", error);
        }
    };

    setLoggedInUser = (user: any) => {
        this.setState({ user });
    };

    retrieveUserDataFromTokenAndRedirect = async (token: any) => {
        if (token) {
            const decodedToken = jwt(token as string) as IJWTTokenPayload;
            const userData: IUserInfo = SetLoginUserInfoMap(
                decodedToken.email,
                decodedToken.userId,
                decodedToken.name,
                decodedToken.accountType,
                token,
                decodedToken.role,
                decodedToken.phoneNumber,
                decodedToken.profileImageUrl,
                decodedToken.mfaEnabled,
                decodedToken.isInternal,
                decodedToken.preferences
            );
            this.setState({ user: userData });
            localStorage.setItem("userData", JSON.stringify(userData));
            LogValues("AuthProvider - token block");
            if (userData.mfaEnabled) {
                // redirect to mfa validate page
                this.props.navigate("/mfa/validate", { replace: true });
            } else {
                let redirectPath = sessionStorage.getItem("redirectPath") || "";
                const isWhitelisted = await this.checkEmailWhitelisted(userData.email);
                const isUserWhiteListed = isWhitelistedUser({
                    user: userData,
                    isWhitelisted
                });
                this.props.navigate(
                    isUserWhiteListed
                        ? redirectPath === "/"
                            ? DefaultLoggedinUserPath
                            : redirectPath
                        : (redirectPath || "").startsWith(Paths.dashboard)
                        ? Paths.landing
                        : redirectPath,
                    { replace: true }
                );
                pushTagEvent("e_logged_in");
            }
        }
    };

    render() {
        if (this.state.loading) {
            return <Progress />;
        }
        if (this.state.securityError) {
            return <CookiesDisabledPopup />;
        }
        const authData: AuthData = {
            signIn: this.signIn,
            signOut: this.signOut,
            setUserData: this.setLoggedInUser,
            keys: this.state.keys,
            retrieveUserDataFromTokenAndRedirect: this.retrieveUserDataFromTokenAndRedirect,
            user: this.state.user,
            setWhitelisted: this.setWhitelisted,
            isWhitelisted: this.state.isWhitelisted
        };
        return <AuthContext.Provider value={authData}>{this.props.children}</AuthContext.Provider>;
    }
}

export const WrappedAuthProvider = (props: any) => {
    const navigate = useNavigate();
    return <AuthProvider navigate={navigate}>{props.children}</AuthProvider>;
};

export function useAuth() {
    return useContext(AuthContext);
}
